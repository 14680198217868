/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://githubuniverse.com/"
  }, "GitHub Universe")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/github/status/1590403103014420481"
  }, "60 hours of GitHub Codespaces free(!) every month. - Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/changelog/2022-11-09-introducing-an-all-new-code-search-and-code-browsing-experience/"
  }, "Introducing an all-new code search and code browsing experience")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2022-11-10-introducing-github-actions-importer/"
  }, "Introducing GitHub Actions Importer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/mona-sans"
  }, "Mona Sans & Hubot Sans")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://githubnext.com/"
  }, "GitHub Next")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://githubnext.com/projects/hey-github/"
  }, "GitHub Next | Hey, GitHub!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://githubnext.com/projects/ai-for-pull-requests/"
  }, "GitHub Next | AI for Pull Requests")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2022-09-01-github-actions-introducing-the-new-larger-github-hosted-runners-beta/"
  }, "GitHub Actions: introducing the new, larger GitHub-hosted runners beta")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://accelerator.github.com/"
  }, "GitHub Accelerator")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.hada.io/topic?id=7784"
  }, "Twitch, 12월 13일부터 한국에서 VOD 기능 지원 중단 예정")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.twitch.tv/ko-kr/2022/11/09/vod-%EC%BD%98%ED%85%90%EC%B8%A0%EC%97%90-%EA%B4%80%ED%95%9C-twitch-%EC%BD%94%EB%A6%AC%EC%95%84-%EC%97%85%EB%8D%B0%EC%9D%B4%ED%8A%B8-%EB%B0%8F-%EC%95%BD%EA%B4%80-%EB%B3%80%EA%B2%BD%EC%9D%98-%EA%B1%B4/"
  }, "VOD 콘텐츠에 관한 Twitch 코리아 업데이트 및 약관 변경의 건")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
